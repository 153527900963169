import React from "react";
import styled from "styled-components";

const FooterStyles = styled.footer`
  border-top: 1px solid var(--body-text-dark-theme);
  display: grid;
  place-items: center;
  height: 150px;
  margin-top: 50px;

  h5 {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
  }
`;

export const Footer = () => {
  return (
    <FooterStyles>
      <h5>Web Developer&apos;s Nobel &copy; {new Date().getFullYear()}</h5>
    </FooterStyles>
  );
};
