import React, { useState } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";

import { NomineesPreview } from "../components/Nominee/NomineePreview";
import { MainTemplate } from "../templates/mainTemplate";
import { Seo } from "../components/Seo";

const NomineesStyles = styled.section`
  /* max-width: var(--narrow-width); */
  margin: 1rem auto;

  .nominee-grid {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(${(props) => props.imageSize}px, 1fr)
    );
  }

  a {
    text-decoration: none;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      display: grid;
      place-items: center;

      span {
        font-size: 1.2rem;
      }
    }
  }

  .range {
    margin-top: 29px;
    width: 300px;
    -webkit-appearance: none;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      box-shadow: none;
      background: #ffffff;
      border-radius: 0px;
      border: 0px solid #010101;
    }
    &::-moz-range-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      box-shadow: none;
      background: #ffffff;
      border-radius: 0px;
      border: 0px solid #010101;
    }

    &::-webkit-slider-thumb {
      box-shadow: none;
      border: 0px solid #ffffff;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
      height: 20px;
      width: 20px;
      border-radius: 22px;
      background: rgba(255, 255, 255, 1);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }
    &::-moz-range-thumb {
      box-shadow: none;
      border: 0px solid #ffffff;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
      height: 20px;
      width: 20px;
      border-radius: 22px;
      background: rgba(255, 255, 255, 1);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }
    &::-moz-focus-outer {
      border: 0;
    }
  }
`;

const Nominees = ({ data }) => {
  const allNominees = data.allSanityNominee.edges;
  let nobels = [];

  const [imageSize, setImageSize] = useState("300");

  allNominees.forEach((nominee) => {
    nominee.node.nobels.forEach(({ title }) => {
      if (!nobels.includes(title)) {
        nobels.push(title);
      }
    });
  });

  return (
    <>
      <Seo title="WDN | Nominees" />
      <MainTemplate>
        <NomineesStyles imageSize={imageSize}>
          <div className="row">
            <h2>Nominees</h2>
            <label>
              <span className="label">Image Sizes</span>
              <input
                className="range"
                type="range"
                min="150"
                value={imageSize}
                max="500"
                step="50"
                onChange={(e) => setImageSize(e.target.value)}
              />
            </label>
          </div>
          <section className="nominee-grid">
            {allNominees.map(({ node, i }) => (
              <Link to={`/nominees/${node.slug.current}`} key={i}>
                <NomineesPreview key={i} nominee={node} imageSize={imageSize} />
              </Link>
            ))}
          </section>
        </NomineesStyles>
      </MainTemplate>
    </>
  );
};

export default Nominees;

export const query = graphql`
  {
    allSanityNominee {
      edges {
        node {
          title
          name
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(sizes: "max-width: 80px 60px 40px")
                }
              }
            }
          }
          nobels {
            title
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
