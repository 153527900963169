import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Nav from "../components/Nav";

const SmallHeaderStyles = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 95px;
  background: var(--background-dark-theme);

  .header {
    width: ${(props) =>
      props.isMainHeader ? "var(--wide-width)" : "var(--narrow-width)"};
    margin: 0 auto;
    display: ${(props) => (props.isMainHeader ? "grid" : "flex")};
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    border-bottom: 1px solid var(--body-text-dark-theme);
    margin-bottom: 1px;
  }

  a {
    text-decoration: none;

    h1 {
      margin: 0;
      padding: 0;
    }

    &:hover {
      color: var(--highlight-dark-theme);
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;

    nav {
      margin-left: 0;
    }
  }
`;

export const SmallHeader = ({ isMainHeader }) => {
  return (
    <SmallHeaderStyles isMainHeader={isMainHeader}>
      <div className="header">
        {isMainHeader && <div />}
        <Link to="/">
          <h1>WDN</h1>
        </Link>
        <Nav />
      </div>
    </SmallHeaderStyles>
  );
};
