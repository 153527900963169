import React from "react";
import styled from "styled-components";

const ImageStyles = styled.div`
  --nominee-img-size: ${(props) => props.size}px;
  position: relative;
  margin: 1rem auto -1rem auto;

  .image-wrapper {
    width: var(--nominee-img-size);
    height: var(--nominee-img-size);
    display: inline-block;
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--body-text-dark-theme);
    overflow: hidden;

    &:hover > .nominee-img {
      filter: grayscale(0%);
    }

    .nominee-img {
      position: relative;
      width: 100%;
      height: auto;
      filter: grayscale(100%);
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        ellipse at center,
        rgba(26, 26, 26, 0) 0%,
        rgba(26, 26, 26, 1) 70%,
        rgba(26, 26, 26, 1) 100%
      );
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 600px) {
    .image-wrapper {
      height: 80vw;
      width: 80vw;
    }
  }
`;

export const ImageTemplate = ({ image, imageSize }) => {
  console.log("image", image);

  return (
    <ImageStyles size={imageSize || "325"}>
      <div className="image-wrapper">
        <img
          className="nominee-img"
          srcSet={image.srcSet}
          sizes={image.sizes}
          src={image.src}
          alt={image.alt || "Nominee"}
        />
      </div>
    </ImageStyles>
  );
};
