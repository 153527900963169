import * as React from "react";
import styled from "styled-components";

import { Footer } from "../components/Footer";
import { SmallHeader } from "../components/SmallHeader";

import "./reset.css";
import "./styles.css";
import "./typography.css";

const MainTemplateStyles = styled.section`
  max-width: var(--wide-width);
  margin: 95px auto 0 auto;
  padding: 0 1rem;
`;

export const MainTemplate = ({ children }) => {
  return (
    <MainTemplateStyles>
      <SmallHeader isMainHeader={true} />
      {children}
      <Footer />
    </MainTemplateStyles>
  );
};
