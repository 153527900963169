import React from "react";
import styled from "styled-components";

import { ImageTemplate } from "../../components/ImageTemplate";

const NomineesPreviewStyles = styled.article`
  text-align: center;

  h3 {
    margin-top: 1rem;
    font-size: clamp(1rem, ${({ fz }) => fz}px, 50px);
  }
`;

export const NomineesPreview = ({ imageSize, nominee }) => {
  console.log("font", imageSize * 0.186);
  const nonimeeImage =
    nominee.image.asset.localFile.childImageSharp.gatsbyImageData.images
      .fallback;

  return (
    <NomineesPreviewStyles fz={imageSize * 0.186}>
      <ImageTemplate image={nonimeeImage} imageSize={imageSize} />
      <h3>{nominee.name}</h3>
    </NomineesPreviewStyles>
  );
};
