import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const NavStyles = styled.nav`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 1rem;
  margin-left: auto;

  a {
    position: relative;
    padding: 1rem;
    outline: none;
    background: transparent;
    cursor: pointer;
    text-decoration: none;
    color: var(--body-text-dark-theme, #fafafa);

    &:hover {
      color: var(--highlight-dark-theme);
      background-color: var(--dark-highlight-transparent);
    }
  }
`;

const Nav = () => {
  return (
    <NavStyles>
      <Link to="/contact">Contact</Link>
      <Link to="/nominees">Nominees</Link>
      <Link to="/nominate">Nominate</Link>
      <Link to="/about">About</Link>
    </NavStyles>
  );
};

export default Nav;
